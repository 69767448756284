/*----------------------------------------*/
/*  02. Header
/*----------------------------------------*/
/* ---Header Top--- */
.header{
    &-top{
        a,
        span{
            vertical-align: middle;
            font-family: $rajdhani;
            margin-left: 5px;
            font-weight: 500;
        }
        &-left{
            display: flex;
            .contact-number{
                padding-right: 60px;
            }
        }
        &-right{
            display: flex;
            justify-content: flex-end;
            @include media-breakpoint-down(sm){
                justify-content: center;
                padding-top: 30px;
            }
            ul{
                display: flex;
                li{
                    position: relative;
                    display: flex;
                    &:not(:first-child){
                        padding-left: 60px;
                        @include media-breakpoint-down(lg){
                            padding-left: 15px;
                        }
                    }
                    &.login-info{
                        a{
                            font-family: $rajdhani;
                            font-weight: 600;
                            span{
                                color: #b4b5b6;
                                font-weight: 500;
                            }
                        }
                    }
                    .minicart{
                        &-count{
                            img{
                                margin-right: 15px;
                            }
                            span{
                                &.quantity{
                                    background-color: $secondary;
                                    border-radius: 100%;
                                    text-align: center;
                                    font-size: 14px;
                                    color: $white;
                                    display: block;
                                    width: 20px;
                                    height: 20px;
                                    line-height: 20px;
                                    position: absolute;
                                    top: -5px;
                                    right: 0;
                                }
                            }
                        }
                    }
                    .search-body{
                        display: none;
                    }
                }
            }
        }
        .header{
            &-logo{
                @include media-breakpoint-down(sm){
                    justify-content: center;
                }
            }
        }
    }
}

/* ---Main Header--- */
.main{
    &-nav{
        display: flex;
        justify-content: flex-end;
        > ul{
            > li{
                display: inline-block;
                &:not(:last-child){
                    padding-right: 55px;
                    @media (max-width: 1199px){
                        padding-right: 30px;
                    }
                }
                > a{
                    font-family: $rajdhani;
                    font-weight: 600;
                    color: $white;
                    display: block;
                    padding-top: 40px;
                    padding-bottom: 40px;
                    font-size: 20px;
                    span{
                        position: relative;
                        display: block;
                        &:before{
                            background-color: $primary;
                            content: "";
                            position: absolute;
                            bottom: -10px;
                            left: 0;
                            width: 0;
                            height: 1px;
                            opacity: 0;
                            visibility: hidden;
                            transition: $baseTransition;
                        }
                    }
                    i{
                        vertical-align: text-top;
                        margin-left: 2px;
                    }
                    &:hover{
                        i,
                        span{
                            color: inherit;
                        }
                    }
                }
                &:hover{
                    > a{
                        color: $primary;
                        span{
                            &:before{
                                width: 100%;
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                        i{
                            color: $primary;
                        }
                    }
                }
                &.separator{
                    padding-right: 35px;
                    a{
                        span{
                            background-image: url('../images/header/icon/separator.png');
                            background-repeat: no-repeat;
                            background-position: right;
                            background-size: contain;
                            padding-right: 35px;
                            display: inline-block;
                            &:before{
                                width: calc(100% - 35px);
                            }
                        }
                    }
                }
                &.hassub-item-wrap{
                    position: relative;
                    a{
                        color: $white;
                        i{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

/* ---Header Logo--- */
.header{
    &-fixed-logo{
        margin: auto 90px auto auto; 
        transform: skew(10deg);
        @media (max-width: 1599px){
            width: calc(100% - 60px);
            margin: 0 auto;
        }
    }
    &-logo-wrap{
        background-color: $white;
        width: 28%;
        height: calc(100% - 20px);
        position: absolute;
        top: 20px;
        left: -10px;
        display: flex;
        align-items: center;
        transform: skew(-10deg);
        @media (max-width: 1599px){
            width: 17%;
        }
        @media (max-width: 1199px){
            width: 20%;
        }
    }
}

/* ---Drop Menu--- */
.drop{
    &-holder{
        position: relative;
        &:hover{
            .drop{
                &-menu{
                    top: 100%;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    &-menu{
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
        background-color: $white;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        top: calc(100% + 30px);
        left: 0;
        transition: $baseTransition;
        width: 250px;
        padding-left: 30px;
        padding-top: 25px;
        padding-bottom: 25px;
        text-align: left;
        z-index: 2;
        @include media-breakpoint-down(xl){
            left: auto;
            right: 0;
        }
        li{
            &:not(:last-child){
                padding-bottom: 10px;
            }
            &:hover{
                a{
                    color: $primary;
                }
            }
            a{
                color: $secondary;
            }
        }
    }
}

/* ---Header Search--- */
.hassub{
    &-body{
        top: calc(100% + 35px);
        position: absolute;
        display: none;
        right: 0;
        z-index: 2;
        &.search-body{
            @include media-breakpoint-down(sm){
                right: -100%;
            }
        }
        .input-field{
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
            padding-right: 70px;
            padding-left: 20px;
            color: $secondary;
            height: 50px;
            border: 0;
        }
        .btn{
            position: absolute;
            top: 0;
            right: 0;
            height: 50px;
            width: 50px;
            padding: 0;
        }
    }
}

/* ---Header Right--- */
.header-right{
    > ul{
        display: flex;
        justify-content: flex-end;
        position: relative;
        li{
            display: inline-block;
            position: relative;
            padding-left: 20px;
            cursor: pointer;
            a{
                color: $white;
            }
        }
    }
}
/* ---Transparent Header--- */
.transparent-header{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    &.sticky{
        background-color: $secondary;
    }
}

/* ---Header Sticky--- */
.sticky {
    animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2);
    background-color: $white;
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	width: 100%;
    display: block;
    .header-logo-wrap{
        top: 0;
        height: 100%;
    }
}